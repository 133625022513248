
  .audio {
    position: relative;
    background-color: rgb(0, 13, 80);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    font-size: calc(10px + 2vmin);
    color: var(--color-gainsboro-100);
    font-family: var(--desktop-menu);
    
  }



.image-container
{ 
  position: absolute;
  border: 2px solid rgba(30, 255, 0, 0);
  width: 100%;
  height: 100%;
}

.image-container .radio-main      
{
  /*width: 80vh; */ /*80*/
  left: 50%/*calc(40vw - 40vh) not sure if replacing is useful, but the calc vw-vh feels weird*/;

  height: 100%;  
  transform: translateX(-50%);                       
  position: absolute;
  display: block;

  z-index: 1;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  
  
}

.slider-container-audio
{
  position: absolute;
  width: 80vh;
  height: 80vh;
  left: 50%;

  transform: translateX(-50%)

}

.slider-container-audio .knob-vol
{  
  width: 1%;
  height: 30%;
  left: 17%;
  top: 60%;

  
  position: absolute;
  z-index: 2;
  transform: /*scale(1)  translateX(20%)   translateY(80%) */  rotate(-2deg);
  transform-origin: center;
}

.knob-vol .MuiSlider-thumb {
  
  height: 50px;


}

.slider-container-audio .knob-playback-speed
{  
  width: 30%;
  height: 7.5%;
  top: 68%;
  left: 47.5%;
  
  position: absolute;
  z-index: 2;
  transform: /*scale(1) translateY(56vh) translateX(20vh) */ rotate(-10deg);
  transform-origin: center;



}

.knob-playback-speed .MuiSlider-thumb {
  
  height: 100px;

}

.slider-container-audio .knob-playback-timer {  
 
 
  width: 30%;
  height: 7.5%;
  /*width: 22vh;
  height: 5vh; */
  top: 53%;
  left: 47.5%;
  
  position: absolute;
  z-index: 2;
  transform: /*scale(1) translateY(43vh) translateX(20vh) */ rotate(-10deg);
  transform-origin: center;
}

.knob-playback-timer .MuiSlider-thumb {
  
  height: 100px;

}

audio {
  display: none;
}