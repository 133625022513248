  .book {
    position: relative;
    background-color: rgb(0, 13, 80);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    font-size: calc(10px + 2vmin);
    color: var(--color-gainsboro-100);
    font-family: var(--desktop-menu);

  }

  .chapter-text {

    color: rgb(255, 210, 76);
    margin: 75px;
    margin-left: 200px;
    margin-right: 200px;
    overflow-y: auto;
    height: 75%;
    padding-right: 10px;


  }
  
.chapter-text {
  white-space: break-spaces;
  color: green;
  border: 3px solid red
}

.page-turn-right {
  cursor:   url(./Salt_Pics/Cursor_Right.png) 100 10, pointer;
}
.page-turn-left {
  cursor:   url(./Salt_Pics/Cursor_left.png) 0 10, auto;
}