.learning-rate-slider-container {
    position: relative;
    display: inline-block;
  }
  
  .local-storage-options-container {
    width: 50%;
    height: 20px;
    position: absolute;
    top: 95%;
    left: 25%;
    
    background-color: rgb(21, 45, 82);
    border: 1px solid #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 9999;
  }

  .local-storage-options-box {
    top: -70%;
  }

  .local-storage-options-save {
    padding: 50px;
  }



  .pre-trained-storage-options-container {
    width: 100%;
    height: 15%;
    position: absolute;
    top: 70%;
    left: 0.5%;
    
    background-color: rgb(21, 45, 82);
    border: 1px solid #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 9999;
  }

  .pre-trained-storage-options-box-button {
    top: -5%;
    width: 90%;
    height: 45%;
  }

  .pre-trained-storage-options-box-slider {
    top: -5%;
    width: 90%;
  }


  
  .learning-rate-slider {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
  }
  
  .learning-rate-slider-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  