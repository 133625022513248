.menu {
  background: rgb(16, 33, 110);
  height: 100vh;
  width: 100vw;
  position: relative;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-gainsboro-100);
  font-family: var(--desktop-menu);
  
}

.title-container {
  position: absolute;
  width: 100vw;
  top: 3vh;
}

.your-thoughts-are {
  flex-direction: column;
  justify-content: center;
  color: #E2E7EE;
  
  text-align: center;
  font-family: Inter;
  font-size: 4vh;
  font-style: normal;
  font-weight: 700;

  letter-spacing: 0.65625rem;
  text-transform: uppercase;
  /*border:  5px solid red; */
}


  .book-port,
  .audio-port,
  .flower-port {
    position: absolute;
    z-index: 10;
    margin: 0 !important;
    
    background-color: var(--color-mediumslateblue);
    width: 16vw;
    height: 6.9vw;
    justify-self: center;
  }
  
  .book-port {
    left: 22.1vw; 
    top: 3.9vw;
  }

  .audio-port {
    left: 62vw;
    top: 3.9vw;
  }

  .flower-port {
    left: 42vw;
    top: 28.2vw   
  } 

  .port-div {
    position: absolute;
    display: flex;
  }


  .div-child {
    flex: 1;
    position: relative;
    max-width: 10vw;
    overflow: hidden;
    height: 3.56rem;
    top: -5vh;
    left: 5vw;
  }
  
  .about {
    position: absolute;
    right: 1vw;
    bottom: -1vh;
    font-size: calc(5px + 1vmin);
    letter-spacing: 0.5vw;
    line-height: 1.4rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    height: 2rem;
    color: white;
    cursor: pointer;
    

  }
  
  .about-container {
    flex: 1;
    position: relative;
    height: 2.31rem;
    right: 0px;
    z-index: 43;
  }

  .aboutmenu p {
    color: rgb(102, 115, 255);
    text-transform: none;
    letter-spacing: 0.1vw;
    font-size: calc(5px + 1.5vmin);
  }

  .aboutmenu header {
    color: white;
  }

  .about-menu-drop-down-wrapper {
    color: rgb(102, 115, 255);
    text-align: right;
    text-transform: none;
    letter-spacing: 0.1vw;
    font-size: calc(5px + 1.5vmin);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  
  .about-menu-drop-down-wrapper.open {
    max-height: 1000px; /* Set a large enough value to accommodate the content */
    transition: max-height 0.5s ease-out;
  }
  
  .about-menu-drop-down-wrapper.closing {
    max-height: 0;
    transition: max-height 0.5s ease-out;
  }

  
  .footer {
    position: absolute;
    bottom: 0vh;
    border-top: 1px solid var(--color-darkslategray-100);
    box-sizing: border-box;
    width: 100vw;
    display: flex;
    flex-direction: row;
    padding: 0vw;
    padding-bottom: 0;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    
  }
  
 
  .nested-div {
    position: relative;
    background-color: rgb(0, 13, 80);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    font-size: calc(10px + 2vmin);
    color: var(--color-gainsboro-100);
    font-family: var(--desktop-menu);

  }



  .svg-container {

    z-index: 5;
  }

  .button-test {

    left: -100px
  }
  
  .port-container {

    position: absolute;

  }

  .route-button {

    position: absolute;
    width: 3.7vw;
    height: 3.7vw;
  }

  .hidden {
    visibility: hidden; 
  }

  /*
  .colour-test {

    background-color: aqua;
    height: 2000px;
    width: 2000px;

  } */


  /* Salt Sim stuff below */

  .ButtonMenu {

    color: rgb(255, 221, 120);
    pointer-events: none;
    left: 25%;
    top: -30vh;
    
  }

  .ButtonMenu networkmenu {

    background-color: rgb(0, 10, 57); 
    border: 5px solid black;
    display: inline-block;
    justify-self: right;
    margin: 0px 0px 0px 0px ;
    position: relative;
    border-radius: 30px 30px 30px 30px;
    padding-left: 2%;
    padding-right: 2%;
    top: -40vh;
    left: -10vw;
    
  }

  .network-menu-wrapper { 
    display: inline-block;
    

    box-sizing: border-box;
    width: auto;


    transition: transform 0.5s ease;
    transform-origin: top left;
  }

  .network-menu-wrapper.collapsed {
    transform: scaleY(0);
    overflow: hidden; 
  }

  .network-menu-wrapper.open {
    transform: scale(1);
  }


  .play-button-lever {
    transition: transform 0.5s ease;
    transform-origin: center;
    cursor: pointer;
  }

  .play-button-lever.off {
    transform: translateX(-50%) rotate(90deg);
  }

  .play-button-lever.on {
    transform: translateX(-50%) rotate(0deg);
  }




/* About Menu stuff below */


aboutmenu {

  background-color: rgb(0, 10, 57); 
  display: inline-block;
  justify-self: left;
  margin: 0px 0px 0px 0px ;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  padding-left: 2%;
  padding-right: 2%;
  top: 10%;
  left: 0vw;
  width: 80%;
  height: 80%;


  font-size: calc(7px + 2vmin);
  letter-spacing: 0.3vw;
  line-height: 1.4rem;
  text-transform: uppercase;
  text-align: left;
  align-items: left;
  color: rgb(102, 115, 255);

  overflow-y: auto;


  
}

aboutmenu p {

  font-size: calc(1px + 1.5vmin);
}

.about-menu-wrapper{ 



  transition: transform 3s ease, right 3s ease;

  
}

.about-menu-wrapper.left {
  transform-origin: left;
  border-radius: 0px 30px 30px 0px;
  left: 0vw;
}

.about-menu-wrapper.right {
  transform-origin: right;
  border-radius: 30px 0px 0px 30px;
  left:60vw
}

.about-menu-wrapper.collapsed {
  transform: scaleX(0);
  right: -100vw;
  

}

.about-menu-wrapper.open {
  transform: scale(1);
  right: -60vw;
}

.about-menu-drop-downs {
  width: 100%;
  padding-bottom: 0.1vh;
  font-size: calc(6px + 1vmin);
  letter-spacing: 0.3vw;
  line-height: 1.4rem;
  text-transform: uppercase;
  text-align: left;
  align-items: left;
  color: rgb(102, 115, 255);
  

  overflow-y: auto;

}


.about-menu-drop-down-buttons {
  text-align: right;
  background-color: rgb(0, 19, 99);
  padding-top: 1%;
  padding-bottom: 1%;
}

.about-menu-drop-down-buttons:hover {
  background-color: rgb(0, 31, 155);
  cursor: pointer;
}

.about-menu-drop-down-wrapper {
  position:absolute;
  transform: scaleY(0); 
  transform-origin: top;
  transition: transform 0.3s ease;
}

.about-menu-drop-down-wrapper.open {
  position:relative;
  transform: scaleY(1);
}